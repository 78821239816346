import * as React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import HubspotForm from 'react-hubspot-form'
import styled from "styled-components"

const StyledDiv = styled.div`
  .text-shadow {
    text-shadow: 0 3px 3px rgba(0,0,0,.6);
  }
`

const SectionPodcastsRequestNewUpdate = () => {
  const data = useStaticQuery(graphql`
    query SectionPodcastsRequestNewUpdateQuery {
      prismicPodcastsPage {
        _previewable
        data {
          top_title3
          main_title3
          section_content2
          hubspot_form_id
          section_background {
            gatsbyImageData(
              width: 1920
            )
          }          
        }
      }
      imgBgDefault: file(relativePath: { eq: "podcasts/backgrounds/request-section-bg-full.jpeg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            height: 1650
          )
        }
      }
    }
  `)

  const doc = data.prismicPodcastsPage
  const imgBg = getImage(doc.data.section_background)
  const imgBgDefault = getImage(data.imgBgDefault)

  return (
    <>
      <div className="relative" style={{ display: "grid", background: "#333333", minHeight: "200px", height: "auto" }}>
        <GatsbyImage
          style={{
            gridArea: "1/1",
            opacity: "0.5",
          }}
          alt="section background"
          image={imgBg}
          formats={["auto", "webp", "avif"]}
        />

        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            placeItems: "center",
            display: "grid",
          }}
        >
          <div className="w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col items-center justify-center px-2 py-16">
            <StyledDiv className="flex flex-col justify-center">
              <p className='text-white text-md md:text-3xl text-center uppercase mb-2 md:mb-4 tracking-widest'>{doc.data.top_title3}</p>
              <h2 className="text-white text-xl md:text-5xl text-center font-extrabold uppercase tracking-wider mb-2 md:mb-8">{doc.data.main_title3}</h2>
              <p className='text-white text-md md:text-2xl text-center mb-4'>{doc.data.section_content2}</p>

              <div className="bg-white w-11/12 max-w-2xl mt-8 md:mt-10 border-2 border-site-gray-dark rounded-3xl p-8 shadow-lg mx-auto">
                <HubspotForm
                  region='na1'
                  portalId='23182726'
                  formId={doc.data.hubspot_form_id}
                  loading={<div className='italic'>Loading request form...</div>}
                />
              </div>
            </StyledDiv>
          </div>
        </div>
      </div>
    </>
  )
}

export default SectionPodcastsRequestNewUpdate
