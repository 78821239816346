import * as React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, StaticImage, getImage, getSrc } from "gatsby-plugin-image"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { RichText } from "prismic-reactjs"
import styled from 'styled-components'

import LayoutUpdate from "../components/layout-update"
import Seo from "../components/seo"
import htmlSerializer from "../utils/htmlSerializer"
import HeroSectionCapVsMicroLanding from "../components/sections/hero-section-cap-vs-micro-landing"
import SectionFourPartTest from "../components/sections/section-four-part-test"
import SectionCapVsMicroTakeAction from "../components/sections/section-cap-vs-micro-take-action"

const StyledDiv = styled.div`
  background-image: linear-gradient(to bottom, #1ea0a0, #f0f5f5); 
`

const PageCaptiveVsMicroCaptiveLandingTemplate = ({ data }) => {
  const doc = data.prismicCaptiveVsMicrocaptivePage
  const imgHero = getImage(doc.data.hero_section_background)
  const imgSection = getImage(doc.data.history_section_image)
  const imgSectionAction = getImage(doc.data.take_action_section_image_background)
  const imgSrc = getSrc(doc.data.hero_section_background)
  const historyButtonOneLinkType = doc.data.history_cta_button_link.link_type
  const historyButtonOneLinkUid = doc.data.history_cta_button_link.uid
  const historyButtonOneLinkUrl = doc.data.history_cta_button_link.url
  const secondSectionButtonOneLinkType = doc.data.second_section_cta_button_link.link_type
  const secondSectionButtonOneLinkUid = doc.data.second_section_cta_button_link.uid
  const secondSectionButtonOneLinkUrl = doc.data.second_section_cta_button_link.url
  const actionSectionButtonOneLinkType = doc.data.take_action_section_cta_button_link.link_type
  const actionSectionButtonOneLinkUid = doc.data.take_action_section_cta_button_link.uid
  const actionSectionButtonOneLinkUrl = doc.data.take_action_section_cta_button_link.url

  return (
    <LayoutUpdate>
      <Seo
        title={doc.data.page_title}
        description={doc.data.page_short_description}
        image={imgSrc}
      />

      <HeroSectionCapVsMicroLanding
        leftTitle={doc.data.hero_section_left_title}
        midTitle={doc.data.hero_section_middle_title}
        rightTitle={doc.data.hero_section_right_title}
        buttonOneTypeHover={doc.data.cta_button_one_type}
        buttonOneLinkType={doc.data.cta_button_link.link_type}
        buttonOneLinkUid={doc.data.cta_button_link.uid}
        buttonOneLinkUrl={doc.data.cta_button_link.url}
        buttonOneName={doc.data.cta_button_text}
        buttonOneNameHover={doc.data.cta_button_one_hover_text}
        imgSrc={imgHero}
      />

      <div className="bg-site-green-light py-12 md:py-24">
        <div className="relative w-11/12 max-w-screen-xl flex flex-col items-center justify-center mx-auto">          
          <div className="z-10">            
            <h2 className="text-site-red text-3xl md:text-4xl text-center font-bold tracking-wider mb-8">{doc.data.intro_section_title}</h2>

            <RichText
              render={doc.data.intro_section_content.richText}
              htmlSerializer={htmlSerializer}
            />
          </div>
        </div>
      </div>

      <div className="bg-site-green-light">
        <div className="relative w-full pt-8 pb-16 md:pt-16 md:pb-14">
          <div className='w-11/12 max-w-screen-xl flex flex-col lg:flex-row items-center justify-center mx-auto lg:space-x-16 p-2'>
            <div className="w-full lg:w-2/3">
              <div className="z-20 flex flex-col items-start justify-start">                
                <h2 className="text-site-red text-3xl md:text-4xl text-center lg:text-left font-bold tracking-wider mb-8">{doc.data.history_section_title}</h2>

                <RichText
                  render={doc.data.history_section_content.richText}
                  htmlSerializer={htmlSerializer}
                />               
              </div>

              {doc.data.history_cta_button_one_type
                ?
                <div className='mt-0 mb-0'>
                  <div className="hidden xl:flex flex-col items-start justify-start">
                    {historyButtonOneLinkType === "Document" || historyButtonOneLinkType === "Any"
                      ?
                      <Link to={historyButtonOneLinkUid ? `/${historyButtonOneLinkUid}` : "/"}>
                        <button id="qualify-button" className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-12 py-3">
                          <span className="default-text mx-2">{doc.data.history_cta_button_text ? doc.data.history_cta_button_text : "Button Name"}</span>
                          <span className="hover-text">{doc.data.history_cta_button_one_hover_text ? doc.data.history_cta_button_one_hover_text : "Button Name Hover"}</span>
                        </button>
                      </Link>
                      :
                      historyButtonOneLinkType === "Web" || historyButtonOneLinkType === "Media"
                      ?
                      <a
                        href={historyButtonOneLinkUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button id="qualify-button" className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-12 py-3">
                          <span className="default-text mx-2">{doc.data.history_cta_button_text ? doc.data.history_cta_button_text : "Button Name"}</span>
                          <span className="hover-text">{doc.data.history_cta_button_one_hover_text ? doc.data.history_cta_button_one_hover_text : "Button Name Hover"}</span>
                        </button>
                      </a>
                      :
                      null
                    }
                  </div>

                  <div className="xl:hidden flex flex-col items-center justify-center">
                    <div className="mb-4">
                      <p className="text-site-red text-2xl font-semibold text-center tracking-wider mb-6">{doc.data.history_cta_button_text ? doc.data.history_cta_button_text : "Button Name"}</p>
                      {historyButtonOneLinkType === "Document" || historyButtonOneLinkType === "Any"
                        ?
                        <Link to={historyButtonOneLinkUid ? `/${historyButtonOneLinkUid}` : "/"}>
                          <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-14 py-3">
                            <span className="mx-1">{doc.data.history_cta_button_one_hover_text ? doc.data.history_cta_button_one_hover_text : "Button Name Hover"}</span>
                          </button>
                        </Link>
                        :
                        historyButtonOneLinkType === "Web" || historyButtonOneLinkType === "Media"
                        ?
                        <a
                          href={historyButtonOneLinkUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-24 py-3">
                            <span className="mx-1">{doc.data.history_cta_button_one_hover_text ? doc.data.history_cta_button_one_hover_text : "Button Name Hover"}</span>
                          </button>
                        </a>
                        :
                        null
                      }
                    </div>
                  </div>
                </div>
                :
                <>
                  {historyButtonOneLinkType === "Document" || historyButtonOneLinkType === "Any"
                    ?
                    <Link to={historyButtonOneLinkUid ? `/${historyButtonOneLinkUid}` : "/"}>
                      <button className="w-full mb-4 xl:mb-0 md:w-auto bg-site-red opacity-70 hover:opacity-90 text-base md:text-lg text-white uppercase tracking-wider px-10 py-3">
                        {doc.data.history_cta_button_text ? doc.data.history_cta_button_text : "Button Name"}
                      </button>
                    </Link>
                    :
                    historyButtonOneLinkType === "Web" || historyButtonOneLinkType === "Media"
                    ?
                    <a
                      href={historyButtonOneLinkUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="w-full mb-4 xl:mb-0 md:w-auto bg-site-red opacity-70 hover:opacity-90 text-base md:text-lg text-whites uppercase tracking-wider px-10 py-3">
                        {doc.data.history_cta_button_text ? doc.data.history_cta_button_text : "Button Name"}
                      </button>
                    </a>
                    :
                    null
                  }
                </>
              }             
            </div>
            <div className="w-full lg:w-1/3 flex flex-col items-center justify-center mt-8 lg:mt-0">
              <GatsbyImage
                alt="section image"
                image={imgSection}
                formats={["auto", "webp", "avif"]}
                className="z-20"
              />              
            </div>
          </div>

          <div className="absolute bottom-0 right-0 z-0">
            <StaticImage
              src="../../images/podcasts/backgrounds/get-started-bg-comp-final.png"
              width={900}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt=""
            />
          </div>
        </div> 
      </div>

      <div id="featured-podcasts" className="bg-site-gray-new pt-16 md:pt-20 pb-8 md:pb-16 z-10">
        <div className="w-11/12 max-w-screen-xl flex flex-col items-center justify-center mx-auto">
          <h2 className='text-gray-100 text-lg md:text-3xl text-center mb-2 tracking-widest'>{doc.data.table_section_top_title}</h2>
          <h2 className="text-white text-2xl md:text-5xl text-center font-extrabold tracking-wider">{doc.data.table_section_main_title}</h2>
        </div>

        <div className="w-11/12 max-w-screen-lg mt-8 mb-4 mx-auto">
          <table class="table-auto w-full border-separate border-spacing-4 border border-slate-500 border-site-gray-new ...">
            <thead>
              <tr>
                <th class="font-body border rounded-lg bg-site-red text-white border-site-red text-xl lg:text-2xl py-2">{doc.data.first_table_column_title}</th>
                <th class="font-body border rounded-lg bg-site-red text-white border-site-red text-xl lg:text-2xl py-2">{doc.data.second_table_column_title}</th>
                <th class="font-body border rounded-lg bg-site-red text-white border-site-red text-xl lg:text-2xl py-2">{doc.data.third_table_column_title}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="font-body border border-white bg-white text-md lg:text-lg text-site-red py-2 font-bold pl-2">{doc.data.first_table_column_text_1}</td>
                <td class="font-body border border-white bg-white text-md lg:text-lg text-site-gray-dark py-2 pl-2">{doc.data.second_table_column_text_1}</td>
                <td class="font-body border border-white bg-white text-md lg:text-lg text-site-gray-dark py-2 pl-2">{doc.data.third_table_column_text_1}</td>
              </tr>
              <tr>
                <td class="font-body border border-white bg-white text-md lg:text-lg text-site-red py-2 font-bold pl-2">{doc.data.first_table_column_text_2}</td>
                <td class="font-body border border-white bg-white text-md lg:text-lg text-site-gray-dark py-2 pl-2">{doc.data.second_table_column_text_2}</td>
                <td class="font-body border border-white bg-white text-md lg:text-lg text-site-gray-dark py-2 pl-2">{doc.data.third_table_column_text_2}</td>
              </tr>
              <tr>
                <td class="font-body border border-white bg-white text-md lg:text-lg text-site-red py-2 font-bold pl-2">{doc.data.first_table_column_text_3}</td>
                <td class="font-body border border-white bg-white text-md lg:text-lg text-site-gray-dark py-2 pl-2">{doc.data.second_table_column_text_3}</td>
                <td class="font-body border border-white bg-white text-md lg:text-lg text-site-gray-dark py-2 pl-2">{doc.data.third_table_column_text_3}</td>
              </tr>
              <tr>
                <td class="font-body border border-white bg-white text-md lg:text-lg text-site-red py-2 font-bold pl-2">{doc.data.first_table_column_text_4}</td>
                <td class="font-body border border-white bg-white text-md lg:text-lg text-site-gray-dark py-2 pl-2">{doc.data.second_table_column_text_4}</td>
                <td class="font-body border border-white bg-white text-md lg:text-lg text-site-gray-dark py-2 pl-2">{doc.data.third_table_column_text_4}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="py-12 md:py-24">
        <div className="relative w-11/12 max-w-screen-xl flex flex-col items-center justify-center mx-auto">          
          <div className="cap-v-micro-section z-10">            
            <h2 className="text-site-red text-3xl md:text-4xl font-bold text-center mb-6">{doc.data.first_section_title}</h2>

            <RichText
              render={doc.data.first_section_content.richText}
              htmlSerializer={htmlSerializer}
            />
          </div>
        </div>
      </div>

      <div className="bg-site-green-light py-12 md:py-24">
        <div className="relative w-11/12 max-w-screen-xl flex flex-col items-center justify-center mx-auto">          
          <div className="cap-v-micro-section z-10">            
            <h2 className="text-site-red text-3xl md:text-4xl font-bold text-center mb-6">{doc.data.        second_section_title}</h2>

            <RichText
              render={doc.data.second_section_content.richText}
              htmlSerializer={htmlSerializer}
            />

            {doc.data.second_section_cta_button_one_type
              ?
              <div className='mt-8 mb-0'>
                <div className="hidden xl:flex flex-col items-center justify-center">
                  {secondSectionButtonOneLinkType === "Document" || secondSectionButtonOneLinkType === "Any"
                    ?
                    <Link to={secondSectionButtonOneLinkUid ? `/${secondSectionButtonOneLinkUid}` : "/"}>
                      <button id="qualify-button" className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-12 py-3">
                        <span className="default-text mx-2">{doc.data.second_section_cta_button_text ? doc.data.second_section_cta_button_text : "Button Name"}</span>
                        <span className="hover-text">{doc.data.second_section_cta_button_one_hover_text ? doc.data.second_section_cta_button_one_hover_text : "Button Name Hover"}</span>
                      </button>
                    </Link>
                    :
                    secondSectionButtonOneLinkType === "Web" || secondSectionButtonOneLinkType === "Media"
                    ?
                    <a
                      href={secondSectionButtonOneLinkUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button id="qualify-button" className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-12 py-3">
                        <span className="default-text mx-2">{doc.data.second_section_cta_button_text ? doc.data.second_section_cta_button_text : "Button Name"}</span>
                        <span className="hover-text">{doc.data.second_section_cta_button_one_hover_text ? doc.data.second_section_cta_button_one_hover_text : "Button Name Hover"}</span>
                      </button>
                    </a>
                    :
                    null
                  }
                </div>

                <div className="xl:hidden flex flex-col items-center justify-center">
                  <div className="mb-4">
                    <p className="text-site-red text-2xl font-semibold text-center tracking-wider mb-6">{doc.data.second_section_cta_button_text ? doc.data.second_section_cta_button_text : "Button Name"}</p>
                    {secondSectionButtonOneLinkType === "Document" || secondSectionButtonOneLinkType === "Any"
                      ?
                      <Link to={secondSectionButtonOneLinkUid ? `/${secondSectionButtonOneLinkUid}` : "/"}>
                        <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-14 py-3">
                          <span className="mx-1">{doc.data.second_section_cta_button_one_hover_text ? doc.data.second_section_cta_button_one_hover_text : "Button Name Hover"}</span>
                        </button>
                      </Link>
                      :
                      secondSectionButtonOneLinkType === "Web" || secondSectionButtonOneLinkType === "Media"
                      ?
                      <a
                        href={secondSectionButtonOneLinkUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-24 py-3">
                          <span className="mx-1">{doc.data.second_section_cta_button_one_hover_text ? doc.data.second_section_cta_button_one_hover_text : "Button Name Hover"}</span>
                        </button>
                      </a>
                      :
                      null
                    }
                  </div>
                </div>
              </div>
              :
              <>
                {secondSectionButtonOneLinkType === "Document" || secondSectionButtonOneLinkType === "Any"
                  ?
                  <Link to={secondSectionButtonOneLinkUid ? `/${secondSectionButtonOneLinkUid}` : "/"}>
                    <button className="w-full mb-4 xl:mb-0 md:w-auto bg-site-red opacity-70 hover:opacity-90 text-base md:text-lg text-white uppercase tracking-wider px-10 py-3">
                      {doc.data.second_section_cta_button_text ? doc.data.second_section_cta_button_text : "Button Name"}
                    </button>
                  </Link>
                  :
                  secondSectionButtonOneLinkType === "Web" || secondSectionButtonOneLinkType === "Media"
                  ?
                  <a
                    href={secondSectionButtonOneLinkUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="w-full mb-4 xl:mb-0 md:w-auto bg-site-red opacity-70 hover:opacity-90 text-base md:text-lg text-whites uppercase tracking-wider px-10 py-3">
                      {doc.data.second_section_cta_button_text ? doc.data.second_section_cta_button_text : "Button Name"}
                    </button>
                  </a>
                  :
                  null
                }
              </>
            }
          </div>
        </div>
      </div>

      <div className="py-12 md:py-24">
        <div className="relative w-11/12 max-w-screen-xl flex flex-col items-center justify-center mx-auto">          
          <div className="cap-v-micro-section z-10">            
            <h2 className="text-site-red text-3xl md:text-4xl font-bold text-center mb-6">{doc.data.third_section_title}</h2>

            <RichText
              render={doc.data.third_section_content.richText}
              htmlSerializer={htmlSerializer}
            />
          </div>
        </div>
      </div>      

      <SectionFourPartTest />

      <SectionCapVsMicroTakeAction 
        topTitle={doc.data.take_action_section_top_title}
        bottomTitle={doc.data.take_action_section_main_title}
        buttonOneTypeHover={doc.data.take_action_section_cta_button_one_type}
        buttonOneLinkType={doc.data.take_action_section_cta_button_link.link_type}
        buttonOneLinkUid={doc.data.take_action_section_cta_button_link.uid}
        buttonOneLinkUrl={doc.data.take_action_section_cta_button_link.url}
        buttonOneName={doc.data.take_action_section_cta_button_text}
        buttonOneNameHover={doc.data.take_action_section_cta_button_one_hover_text}
        imgSrc={imgSectionAction}
      />
    </LayoutUpdate>
  )
}

export default withPrismicPreview(PageCaptiveVsMicroCaptiveLandingTemplate)

export const PageCaptiveVsMicroCaptiveLandingTemplateQuery = graphql`
  query($id: String!) {
    prismicCaptiveVsMicrocaptivePage(id: { eq: $id }) {
      _previewable
      uid
      data {
        page_title
        page_short_description
        hero_section_left_title
        hero_section_middle_title
        hero_section_right_title
        hero_section_background {
          gatsbyImageData(width: 1920)
        }
        cta_button_one_type
        cta_button_text
        cta_button_one_hover_text
        cta_button_link {
          uid
          link_type
          url
        }
        intro_section_title
        intro_section_content {
          richText
        }
        history_section_title
        history_section_content {
          richText
        }
        history_cta_button_one_type
        history_cta_button_text
        history_cta_button_one_hover_text
        history_cta_button_link {
          uid
          link_type
          url
        }
        history_section_image {
          gatsbyImageData(width: 959)
        }
        table_section_top_title
        table_section_main_title
        first_table_column_title
        first_table_column_text_1
        first_table_column_text_2
        first_table_column_text_3
        first_table_column_text_4
        second_table_column_title
        second_table_column_text_1
        second_table_column_text_2
        second_table_column_text_3
        second_table_column_text_4
        third_table_column_title
        third_table_column_text_1
        third_table_column_text_2
        third_table_column_text_3
        third_table_column_text_4
        first_section_title
        first_section_content {
          richText
        }
        second_section_title
        second_section_content {
          richText
        }
        second_section_cta_button_one_type
        second_section_cta_button_text
        second_section_cta_button_one_hover_text
        second_section_cta_button_link {
          uid
          link_type
          url
        }
        third_section_title
        third_section_content {
          richText
        }
        take_action_section_top_title
        take_action_section_main_title
        take_action_section_cta_button_one_type
        take_action_section_cta_button_text
        take_action_section_cta_button_one_hover_text
        take_action_section_cta_button_link {
          uid
          link_type
          url
        }
        take_action_section_image_background {
          gatsbyImageData(width: 1920)
        }
      }
    }
  }
`