import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import { Date } from "prismic-reactjs"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

import LayoutUpdate from "../../components/layout-update"
import HeroSection from "../../components/common/hero-section"
import Seo from "../../components/seo"
import TopBlogPosts from "../../components/top-blog-posts"

const h2Style = "text-xl md:text-2xl mb-4 font-extrabold md:line-clamp-3 mb-6"
const pStyle = "text-base line-clamp-3 mb-8"
const dateStyle = "text-md mb-6"

const PageBlog = () => {
  const data = useStaticQuery(graphql`
    query PageBlogQuery {
      prismicBlogListPage {
        _previewable
        data {
          page_title
          page_short_description
          page_hero_background {
            gatsbyImageData(width: 1920)
          }
        }
      }
      allPrismicBlogPost(sort: { fields: data___date_published, order: DESC }) {
        edges {
          node {
            id
            uid
            data {
              page_title
              post_excerpt
              date_published
              page_hero_background {
                alt
                gatsbyImageData(width: 1920)
              }
            }
          }
        }
      }
      imgHero: file(relativePath: { eq: "backgrounds/blog-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1920)
        }
      }
    }
  `)

  const doc = data.prismicBlogListPage
  const blogPosts = data.allPrismicBlogPost.edges
  const imgHero = getImage(doc.data.page_hero_background)
  const imgSrc = getSrc(doc.data.page_hero_background)

  return (
    <LayoutUpdate>
      <Seo
        title={doc.data.page_title}
        description={doc.data.page_short_description}
        image={imgSrc}
      />

      <HeroSection title={doc.data.page_title} imgSrc={imgHero} />

      <TopBlogPosts />

      <div className="bg-site-green-light py-12 lg:py-24">
        <div className="w-11/12 md:w-8/12 xl:w-5/12 mx-auto border-b-2 border-site-gray-dark pb-5">
          <h2 className="text-site-red text-2xl md:text-4xl uppercase font-extrabold text-center tracking-wider">
            View All
          </h2>
        </div>

        <div className="relative w-11/12 lg:w-10/12 max-w-screen-xl grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 mx-auto mt-16">
          {blogPosts.map(item => {
            const blogImage = getImage(item.node.data.page_hero_background)
            const datePosted = Date(item.node.data.date_published)
            const formattedDate = new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            }).format(datePosted)

            return (
              <div className="relative bg-white border border-gray-100 shadow-lg">
                <div>
                  <Link to={`/blog/${item.node.uid}`}>
                    <GatsbyImage image={blogImage} alt="blog image" />
                  </Link>
                </div>

                <div className="p-8">
                  <p className={dateStyle}>{formattedDate}</p>
                  <Link to={`/blog/${item.node.uid}`}>
                    {item.node.data.page_title.length >= 45 ? (
                      <div className="tooltip relative">
                        <h2 className={h2Style}>{item.node.data.page_title}</h2>
                        <span className="tooltiptext">
                          {item.node.data.page_title}
                        </span>
                      </div>
                    ) : (
                      <h2 className={h2Style}>{item.node.data.page_title}</h2>
                    )}
                  </Link>
                  <p className={pStyle}>{item.node.data.post_excerpt}</p>
                  <Link to={`/blog/${item.node.uid}`}>
                    <button className="absolute bottom-0 left-0 right-0 w-full bg-site-red hover:bg-site-red-hover text-sm text-white tracking-wider mt-6 p-3">
                      Read More
                    </button>
                  </Link>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </LayoutUpdate>
  )
}

export default withPrismicPreview(PageBlog)
