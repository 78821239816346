import React, { useState, useEffect, useRef } from "react"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination } from "swiper/modules"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { RichText } from "prismic-reactjs"
import HubspotForm from "react-hubspot-form"
import styled from "styled-components"
import { Clock4, MapPin } from "lucide-react"
import LayoutNew from "../components/layout-new"
import Seo from "../components/seo"
import htmlSerializer from "../utils/htmlSerializer"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import { VimeoPlayer } from "reactjs-vimeo-player"
import Carousel from "../components/common/carousel"
import { ChevronRightIcon } from "@heroicons/react/solid"
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronDoubleUpIcon,
} from "@heroicons/react/outline"

const StyledBgGray = styled.div`
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  padding-top: 3rem;
  padding-bottom: 3rem;
`

const StyledBgWhite = styled.div`
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding-top: 3rem;
  padding-bottom: 3rem;
`

const PageSymposiumTemplate = ({ data }) => {
  const swiperRef = useRef(null)
  const doc = data.prismicSymposium
  const imgHero = getImage(doc.data.page_hero_background)
  const imgSrc = getSrc(doc.data.page_hero_background)
  const imgDefault = getImage(data.imgDefault)
  const photoPlaceholder = getImage(data.photoPlaceholder)

  const imgAgenda = getImage(doc.data.agenda_background)
  const bgImage = convertToBgImage(imgAgenda)

  // "About Presenters" Section Logic
  const [showAll, setShowAll] = useState(false)
  const [isSmallScreen, setIsSmallScreen] = useState(false)

  const [isClickedLeft, setIsClickedLeft] = useState(false)
  const [isClickedRight, setIsClickedRight] = useState(false)

  const handleLeftClick = () => {
    setIsClickedLeft(true)
    swiperRef.current?.slidePrev()

    // Reset the state after some time
    setTimeout(() => setIsClickedLeft(false), 300)
  }

  const handleRightClick = () => {
    setIsClickedRight(true)
    swiperRef.current?.slideNext()

    // Reset the state after some time
    setTimeout(() => setIsClickedRight(false), 300)
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      const updateScreenSize = () => {
        setIsSmallScreen(window.innerWidth < 768)
      }

      // Set initial value
      updateScreenSize()

      // Add event listener for resize
      window.addEventListener("resize", updateScreenSize)

      // Cleanup event listener
      return () => window.removeEventListener("resize", updateScreenSize)
    }
  }, [])

  const presentersToShow = () => {
    if (!showAll && isSmallScreen) {
      return doc.data.bio_section.slice(0, 4) // Show only the first 4 on small screens
    }
    return doc.data.bio_section // Show all on larger screens or when "showAll" is true
  }

  let titleCounter = 0 // Counter to track numbering for titles
  let titlecountmob = 0
  let currentSide = "left" // Initial side for the first title

  return (
    <LayoutNew>
      <Seo
        title={doc.data.page_title}
        description={doc.page_short_description}
        image={imgSrc}
      />

      {/* hero section */}

      <div className="h-[620px] relative">
        {/* Main Section */}
        <div
          className="h-[88%] bg-cover bg-center"
          style={{
            backgroundImage: `url(${imgHero ? imgSrc : getSrc(imgDefault)})`,
          }}
        >
          {/* Content inside the main section */}
        </div>

        {/* Red Section */}
        <div className=" absolute bottom-0 left-1/2 -translate-x-1/2 max-w-[900px] px-4 w-full mx-auto flex flex-col gap-14 max-md:gap-24">
          <div className=" flex flex-col gap-8 max-w-[777px] mx-auto">
            <h1 className="xl:text-6xl text-4xl max-sm:font-semibold uppercase font-extrabold text-white text-center ">
              {doc.data.page_title}
            </h1>
            {doc.data.hero_section_button_link_type ? (
              <div className="w-full flex items-center justify-center mt-6">
                {doc.data.button_link.link_type === "Document" ||
                doc.data.button_link.link_type === "Any" ? (
                  <Link
                    to={
                      doc.data.button_link.uid ? doc.data.button_link.uid : `/`
                    }
                  >
                    <button className="w-full bg-site-red hover:bg-site-red-hover text-xl  text-white uppercase tracking-wider px-8 py-3">
                      {doc.data.button_name
                        ? doc.data.button_name
                        : "Button Name"}
                    </button>
                  </Link>
                ) : doc.data.button_link.link_type === "Web" ||
                  doc.data.button_link.link_type === "Media" ? (
                  <a
                    href={
                      doc.data.button_link.url ? doc.data.button_link.url : `/`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="btn-custom bg-site-red hover:bg-site-red-hover text-xl text-white uppercase tracking-wider">
                      {doc.data.button_name
                        ? doc.data.button_name
                        : "Button Name"}
                    </button>
                  </a>
                ) : null}
              </div>
            ) : (
              <div className="w-full flex items-center justify-center ">
                <a>
                  <button
                    onClick={() => {
                      const element = document.getElementById("form/")
                      if (element) {
                        element.scrollIntoView({ behavior: "smooth" })
                      }
                    }}
                    className="btn-custom bg-site-red hover:bg-site-red-hover text-xl text-white uppercase tracking-wider"
                  >
                    {doc.data.button_name
                      ? doc.data.button_name
                      : "Button Name"}
                  </button>
                </a>
              </div>
            )}
          </div>
          <div className="xl:py-10 xl:px-16  py-8 px-6 w-full bg-[#991B1B] flex justify-center items-center rounded-full">
            <div
              className=" flex flex-col gap-2 items-center text-center"
              id="Big-text"
            >
              <h2 className="text-white text-4xl font-semibold max-md:text-base">
                {doc.data.title_for_icon.text}
              </h2>
              <div className="flex gap-1 max-sm:px-[1.75rem]  md:gap-2 md:items-center">
                <div className="h-7 w-7 max-sm:h-[1.37rem] max-sm:w-6 flex justify-center items-center bg-white rounded-full">
                  <MapPin className="h-5 w-5 max-sm:h-4 max-sm:w-4 text-black " />
                </div>

                <p className="text-xl max-sm:text-sm text-start font-semibold text-white">
                  {doc.data.p_for_icon.text}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* form section */}
      <div className=" ">
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:gap-28 gap-[68px] md:gap-5 max-w-6xl  mx-auto font-medium text-left text-lg md:text-xl py-12 md:py-20  px-6 lg:px-0">
          {/* Description Section */}
          {/* <div className="space-y-6">
            <h2 className=" font-semibold text-4xl max-sm:text-2xl  ">
              Explore 831(b) Plan at the 2025 SRA Symposium.
            </h2>
            <p className="md:text-lg text-base pb-10 text-black">
              The last few years have more than validated the importance of an
              831(b) Plan and we expect 831(b) Plans to become the next common
              business practice.
              <br />
              <br /> We are thrilled to host{" "}
              <strong>THE 2025 SRA SYMPOSIUM</strong> in Boise, Idaho. This
              exclusive, three-day, invitation-only event is tailored for
              trusted business advisors eager to explore SRA’s expertise in
              administering 831(b) Plans.
              <br /> <br /> This year, we’re elevating the experience with
              Continuing Education (CE) credits for insurance agents, financial
              advisors, and CPAs, reaffirming our commitment to professional
              growth.
              <br /> <br /> Don’t miss this opportunity to deepen your
              understanding of 831(b) Plans and the future of captive
              management. Complete the registration form below to request your
              invitation.
            </p>
            </div> */}
          <div className="space-y-6">
            {doc.data.symposium_description ? (
              <RichText
                render={doc.data.symposium_description.richText}
                htmlSerializer={htmlSerializer}
              />
            ) : (
              `&nbsp;`
            )}
          </div>
          {/* {doc.data.symposium_description ? (
              <RichText
                render={doc.data.symposium_description.richText}
                htmlSerializer={htmlSerializer}
              />
            ) : (
              `&nbsp;`
            )} */}

          {/* Form Section */}
          <div
            id="form/"
            className="w-full container flex flex-col items-center justify-center rounded-3xl border border-black  md:px-0"
          >
            <div className="w-11/12 bg-white border-site-gray-dark text-black  mx-auto text-center rounded-3xl  flex flex-col items-center">
              <div
                className="bg-site-green text-white font-bold mx-auto py-3 px-6 
             uppercase rounded-full tracking-wider text-lg custom-mt"
              >
                {doc.data.form_section_title
                  ? doc.data.form_section_title
                  : "Register"}
              </div>

              <div className="my-2 md:my-8 w-full  text-4xl">
                <HubspotForm
                  portalId="23182726"
                  formId={doc.data.form_id}
                  loading={
                    <div className="italic">Loading request form...</div>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Agenda section */}
      {doc.data.agenda_section_shown ? (
        <BackgroundImage
          // Spread bgImage into BackgroundImage:
          {...bgImage}
          preserveStackingContext
          className="w-full flex flex-col items-center justify-center py-24 bg-blend-darken bg-black bg-opacity-20 px-6 md:px-4"
        >
          {/* mobile */}
          <div className="w-full bg-white opacity-85 border-site-gray-dark text-black max-w-5xl mx-auto text-center rounded-3xl py-6 flex flex-col items-center md:hidden gap-5">
            <div className=" text-black font-bold mx-auto   text-4xl">
              AGENDA
            </div>

            <div className="w-11/12 mx-auto mb-4">
              {/* {doc.data.agenda
                ? doc.data.agenda.map((agenda, index) => {
                    return (
                      <div key={`agenda${index}`} className="text-lg mt-4">
                        {agenda.title ? (
                          <div className="text-site-red font-bold mt-10">
                            {agenda.title}
                          </div>
                        ) : null}
                        <div className="font-bold border border-black rounded-md pt-2">
                          {agenda.activity}
                          <div className="font-light text-sm ">
                            <RichText
                              render={agenda.activity_details.richText}
                              htmlSerializer={htmlSerializer}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  })
                : null} */}
              {doc.data.agenda
                ? doc.data.agenda.map((agenda, index) => {
                    let titleSide = currentSide
                    let dataSide = currentSide === "left" ? "right" : "left"

                    // Increment titleCounter and switch sides when a title exists
                    if (agenda.title) {
                      titlecountmob++ // Increment the counter for titles
                      currentSide = currentSide === "left" ? "right" : "left"
                      titleSide = currentSide
                      dataSide = currentSide === "left" ? "right" : "left"
                    }
                    return (
                      <div
                        key={`agenda${index}`}
                        className="text-lg flex flex-col gap-6 mt-5"
                      >
                        {agenda.title ? (
                          <div className="flex flex-col gap-4">
                            <div className="w-12 mt-7 h-12 rounded-full border-4 mx-auto border-red-600 bg-white text-black flex items-center justify-center font-bold z-10">
                              {titlecountmob}
                            </div>
                            <div className="text-site-red font-bold text-2xl">
                              {agenda.title}
                            </div>
                          </div>
                        ) : null}
                        <div className="font-bold px-4 text-start border py-6 border-black rounded-md">
                          {agenda.activity}
                          <div className="font-light text-sm text-left flex flex-col">
                            <span>{agenda.activity_details.text}</span>
                            <span className="flex gap-2">
                              {agenda.tile_for_agenda.text && (
                                <Clock4 className="h-3 w-3 text-black mt-1" />
                              )}
                              {agenda.tile_for_agenda.text}
                            </span>
                            <span className="flex gap-2">
                              {agenda.tile_for_agenda.text && (
                                <MapPin className="h-3 w-3 text-black mt-1" />
                              )}

                              {agenda.location_for_agenda.text}
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                  })
                : null}
            </div>
          </div>
          {/* desktop */}
          <div className="w-full bg-white opacity-85 border-site-gray-dark text-black max-w-5xl mx-auto text-center rounded-3xl py-8 flex flex-col items-center max-md:hidden">
            <div className="text-black font-extrabold pb-16 mx-auto xl:text-6xl mb-12">
              AGENDA
            </div>

            <div className="w-11/12 mx-auto mb-4 relative">
              {/* Center timeline line */}
              <div className="absolute left-1/2 transform -translate-x-px -mt-14 w-0.5 h-[104%] bg-[#959797]" />

              {/* our main logic for agenda */}
              {doc.data.agenda && doc.data.agenda.length > 0
                ? doc.data.agenda.map((item, index) => {
                    // Determine the side
                    let titleSide = currentSide
                    let dataSide = currentSide === "left" ? "right" : "left"

                    // Increment titleCounter and switch sides when a title exists
                    if (item.title) {
                      titleCounter++ // Increment the counter for titles
                      currentSide = currentSide === "left" ? "right" : "left"
                      titleSide = currentSide
                      dataSide = currentSide === "left" ? "right" : "left"
                    }

                    return (
                      <div
                        key={`agenda${index}`}
                        className="relative mb-16 last:mb-0"
                      >
                        <div className="flex items-start  justify-between">
                          {/* Title Section */}
                          {item.title && (
                            <div
                              className={` w-5/12 ${
                                titleSide === "left"
                                  ? "order-1 text-right "
                                  : "order-3 text-left "
                              }`}
                            >
                              {/* Circle Number */}
                              <div className="absolute -mt-2 left-1/2 transform -translate-x-1/2 flex items-center justify-center">
                                <div className="w-12 h-12 rounded-full border-4 border-red-600 bg-white text-black flex items-center justify-center font-bold z-10 ">
                                  {titleCounter}
                                </div>
                              </div>
                              <div className="text-site-red font-bold text-4xl  mb-2 -mt-2 capitalize">
                                {item.title}
                              </div>
                            </div>
                          )}

                          {/* Center Spacer for Timeline */}
                          <div className="w-2/12 order-2"></div>

                          {/* Activity and Details Section */}
                          <div
                            className={`w-5/12 ${
                              dataSide === "left"
                                ? "order-1  -mt-12 text-left "
                                : "order-3 text-left -mt-12  "
                            }`}
                          >
                            <div
                              className={`border border-black rounded-md p-4 ${
                                dataSide === "left" ? "" : ""
                              }`}
                            >
                              <div className="font-semibold text-3xl mb-2 capitalize">
                                {item.activity}
                              </div>
                              <div className="font-light text-sm  text-left flex flex-col">
                                <span className="">
                                  {item.activity_details.text}
                                </span>
                                <span className="flex gap-2">
                                  {item.tile_for_agenda.text && (
                                    <Clock4 className="h-3 w-3 text-black mt-1" />
                                  )}
                                  {item.tile_for_agenda.text}
                                </span>
                                <span className="flex gap-2">
                                  {item.location_for_agenda.text && (
                                    <MapPin className="h-3 w-3 text-black mt-1" />
                                  )}
                                  {item.location_for_agenda.text}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                : null}
            </div>
          </div>
        </BackgroundImage>
      ) : null}

      {/* about our */}
      {doc.data.presenters_section_shown ? (
        // <div className="w-full flex flex-col">
        //   <div className="w-11/12 mx-auto pt-8 sm:pt-24">
        //     <h2 className="text-site-red text-4xl md:text-6xl font-extrabold text-center mt-4 mb-8 uppercase">
        //       About our Presenters
        //     </h2>
        //   </div>

        //   {doc.data.bio_section.map((presenter, index) => {
        //     const teamPhoto = getImage(presenter.photo)
        //     return (
        //       <div>
        //         {presenter.section_background ? (
        //           <StyledBgGray key={`presenter-${index}`}>
        //             <div className="relative w-10/12 md:w-8/12 xl:w-10/12 max-w-screen-lg flex flex-col xl:flex-row items-center justify-center mx-auto xl:space-x-6">
        //               <div className="w-full xl:w-4/12 flex justify-center p-6">
        //                 <GatsbyImage
        //                   image={teamPhoto ? teamPhoto : photoPlaceholder}
        //                   alt={
        //                     presenter.photo.alt
        //                       ? presenter.photo.alt
        //                       : `presenter${index}`
        //                   }
        //                   className="rounded-lg shadow-lg"
        //                 />
        //               </div>
        //               <div className="w-full xl:w-8/12">
        //                 <div className="mb-2">
        //                   <h2 className="text-site-red text-xl md:text-2xl uppercase font-extrabold text-center tracking-wider">
        //                     {presenter.name ? presenter.name : "Team Name"}
        //                   </h2>
        //                 </div>
        //                 <div className="lg:w-8/12 mx-auto border-t-2 border-site-gray-dark pt-2 mb-8">
        //                   <h3 className="text-site-gray-dark text-sm md:text-base uppercase font-bold text-center tracking-wider">
        //                     {presenter.position
        //                       ? presenter.position
        //                       : "Position"}
        //                   </h3>
        //                 </div>

        //                 {/* <p className="text-base">
        //                   {presenter.short_bio
        //                     ? presenter.short_bio
        //                     : "Short bio should go here."}
        //                 </p> */}
        //               </div>
        //             </div>
        //           </StyledBgGray>
        //         ) : (
        //           <StyledBgWhite key={`presenter-${index}`}>
        //             <div className="relative w-10/12 md:w-8/12 xl:w-10/12 max-w-screen-lg flex flex-col xl:flex-row items-center justify-center mx-auto xl:space-x-6">
        //               <div className="w-full xl:w-4/12 flex justify-center p-6">
        //                 <GatsbyImage
        //                   image={teamPhoto ? teamPhoto : photoPlaceholder}
        //                   alt={
        //                     presenter.photo.alt
        //                       ? presenter.photo.alt
        //                       : `presenter${index}`
        //                   }
        //                   className="rounded-lg shadow-lg"
        //                 />
        //               </div>
        //               <div className="w-full xl:w-8/12">
        //                 <div className="mb-2">
        //                   <h2 className="text-site-red text-xl md:text-2xl uppercase font-extrabold text-center tracking-wider">
        //                     {presenter.name ? presenter.name : "Team Name"}
        //                   </h2>
        //                 </div>
        //                 <div className="lg:w-8/12 mx-auto border-t-2 border-site-gray-dark pt-2 mb-8">
        //                   <h3 className="text-site-gray-dark text-sm md:text-base uppercase font-bold text-center tracking-wider">
        //                     {presenter.position
        //                       ? presenter.position
        //                       : "Position"}
        //                   </h3>
        //                 </div>

        //                 {/* <p className="text-base">
        //                   {presenter.short_bio
        //                     ? presenter.short_bio
        //                     : "Short bio should go here."}
        //                 </p> */}
        //               </div>
        //             </div>
        //           </StyledBgWhite>
        //         )}
        //       </div>
        //     )
        //   })}

        //   {doc.data.show_button ? (
        //     <div className="w-10/12 xl:w-full flex items-center justify-center mx-auto max-w-screen-xl mt-10 mb-14 md:mb-24">
        //       {doc.data.presenters_section_button_link.link_type ===
        //         "Document" ||
        //       doc.data.presenters_section_button_link.link_type === "Any" ? (
        //         <Link
        //           to={
        //             doc.data.presenters_section_button_link.uid
        //               ? doc.data.presenters_section_button_link.uid
        //               : `#form/`
        //           }
        //         >
        //           <button className="btn-custom bg-site-red hover:bg-site-red-hover text-xl text-white uppercase tracking-wider">
        //             {doc.data.presenters_section_button_name
        //               ? doc.data.presenters_section_button_name
        //               : "Register Now"}
        //           </button>
        //         </Link>
        //       ) : doc.data.presenters_section_button_link.link_type === "Web" ||
        //         doc.data.presenters_section_button_link.link_type ===
        //           "Media" ? (
        //         <a
        //           href={
        //             doc.data.presenters_section_button_link.url
        //               ? doc.data.presenters_section_button_link.url
        //               : `#form/`
        //           }
        //           target="_blank"
        //           rel="noopener noreferrer"
        //         >
        //           <button className="btn-custom bg-site-red hover:bg-site-red-hover text-xl text-white uppercase tracking-wider">
        //             {doc.data.presenters_section_button_name
        //               ? doc.data.presenters_section_button_name
        //               : "Register Now"}
        //           </button>
        //         </a>
        //       ) : null}
        //     </div>
        //   ) : null}
        // </div>
        <div className="w-full container mx-auto md:px-4 flex mb-28 flex-col">
          <div className="w-11/12 mx-auto pt-8 sm:pt-8">
            <h2 className="text-black text-4xl md:text-6xl font-extrabold text-center mt-4 mb-8 uppercase">
              About our Presenters
            </h2>
          </div>

          <div className="flex max-sm:flex-col flex-wrap items-baseline justify-center  gap-4 w-11/12 mx-auto">
            {presentersToShow().map((presenter, index) => {
              const teamPhoto = getImage(presenter.photo)
              return (
                <div
                  key={`presenter-${index}`}
                  className="flex flex-col pb-2  xl:w-[23%] justify-center md:w-[30%] sm:w-full"
                >
                  <div className="w-full aspect-square mb-4">
                    <GatsbyImage
                      image={teamPhoto ? teamPhoto : photoPlaceholder}
                      alt={
                        presenter.photo.alt
                          ? presenter.photo.alt
                          : `presenter${index}`
                      }
                      className="rounded-lg  w-full h-full object-cover"
                    />
                  </div>
                  <h2 className="text-black text-xl font-extrabold text-center tracking-wider uppercase">
                    {presenter.name ? presenter.name : "Team Name"}
                  </h2>
                  <div className="w-full  pt-2">
                    <h3 className="text-site-gray-dark text-center text-sm uppercase font-medium">
                      {presenter.position ? presenter.position : "Position"}
                    </h3>
                  </div>
                </div>
              )
            })}
          </div>

          {/* Show "View More" button only on small screens and when there are more than 4 presenters */}
          {typeof window !== "undefined" &&
            window.innerWidth < 768 &&
            !showAll &&
            doc.data.bio_section.length > 4 && (
              <div className="w-full flex items-center justify-center mx-auto max-w-screen-xl mt-10 mb-14">
                <button
                  onClick={() => setShowAll(true)}
                  className="btn-custom bg-site-red hover:bg-site-red-hover text-xl text-white uppercase tracking-wider"
                >
                  View More
                </button>
              </div>
            )}
        </div>
      ) : null}

      {/* slider */}
      {doc.data.videos_section_shown || doc.data.pictures_section_shown ? (
        <div className="w-full   flex flex-col bg-site-gray-light">
          <div className="container mx-auto">
            <div className="w-11/12 mx-auto pt-8 sm:pt-24">
              <h2 className="text-black text-4xl md:text-6xl font-semibold px-4  md:font-extrabold text-center mt-4 uppercase">
                {doc.data.videos_title ? doc.data.videos_title : "Video Title"}
              </h2>
            </div>
            {doc.data.videos_section_shown ? (
              <div className="w-full">
                <h2 className="text-xl md:text-sm px-6  font-normal text-center mt-4">
                  {doc.data.videos_description
                    ? doc.data.videos_description
                    : "Video Description"}
                </h2>
              </div>
            ) : null}

            {doc.data.videos_section_shown ? (
              <div className="relative">
                <Swiper
                  modules={[Navigation]}
                  onSwiper={swiper => (swiperRef.current = swiper)}
                  spaceBetween={15}
                  slidesPerView={1}
                  breakpoints={{
                    640: { slidesPerView: 1 },
                    768: { slidesPerView: 2 },
                    1024: { slidesPerView: 3 },
                  }}
                  className="mt-8 md:w-[80%] max-md:w-[95%] px-3"
                >
                  {doc?.data?.videos && doc.data.videos.length > 0 ? (
                    doc.data.videos.map((video, index) => (
                      <SwiperSlide key={`video-${index}`}>
                        <div className="container w-full shadow-lg border border-black rounded-md overflow-hidden">
                          <VimeoPlayer
                            id={video.id}
                            responsive
                            className="w-full h-full object-cover"
                          />
                        </div>
                      </SwiperSlide>
                    ))
                  ) : (
                    <SwiperSlide>
                      <div className="text-center text-gray-500">
                        No videos available
                      </div>
                    </SwiperSlide>
                  )}
                </Swiper>
                {doc.data.videos.length > 3 && (
                  <div className="max-md:hidden text-gray-700 mt-5 px-6 right-4 lg:top-4 lg:bottom-auto lg:px-28">
                    {/* Left Button */}
                    <button
                      onClick={handleLeftClick}
                      className={`border border-gray-900 absolute md:left-4 xl:left-10 z-50 top-1/2 -translate-y-1/2 rounded-full flex items-center justify-center w-12 h-12 p-2 duration-300 ${
                        isClickedLeft
                          ? "bg-primary text-primary-foreground"
                          : "hover:bg-primary hover:text-primary-foreground"
                      }`}
                    >
                      <ArrowLeftIcon size={30} />
                    </button>

                    {/* Right Button */}
                    <button
                      onClick={handleRightClick}
                      className={`border border-gray-900 absolute rounded-full md:right-4 xl:right-10 z-50 top-1/2 -translate-y-1/2 flex items-center justify-center w-12 h-12 p-2 duration-300 ${
                        isClickedRight
                          ? "bg-[#BB0000] text-white"
                          : "hover:bg-[#BB0000] hover:text-white"
                      }`}
                    >
                      <ArrowRightIcon size={30} />
                    </button>
                  </div>
                )}

                <div className="lg:hidden flex justify-center gap-4 text-gray-700 mt-12 px-6 right-4 lg:top-4 lg:bottom-auto lg:px-28">
                  {/* Left Button */}
                  <button
                    onClick={handleLeftClick}
                    className={`border border-gray-900 z-50 top-1/2 -translate-y-1/2 rounded-full flex items-center justify-center w-12 h-12 p-2 duration-300 ${
                      isClickedLeft
                        ? "bg-[#BB0000] text-white"
                        : "hover:bg-[#BB0000] hover:text-white"
                    }`}
                  >
                    <ArrowLeftIcon size={20} />
                  </button>

                  {/* Right Button */}
                  <button
                    onClick={handleRightClick}
                    className={`border border-gray-900 rounded-full -translate-y-1/2 p-2 duration-300 flex items-center justify-center w-12 h-12 ${
                      isClickedRight
                        ? "bg-[#BB0000] text-white"
                        : "hover:bg-[#BB0000] hover:text-white"
                    }`}
                  >
                    <ArrowRightIcon size={20} />
                  </button>
                </div>
              </div>
            ) : null}

            {doc.data.pictures_section_shown ? (
              <div className="w-9/12 xl:w-10/12 mx-auto max-w-screen-xl mt-10 mb-14 md:mb-24"></div>
            ) : null}

            {doc.data.show_button1 ? (
              <div className="w-10/12 max-md:mt-3 lg:mt-10 xl:w-full flex items-center justify-center mx-auto max-w-screen-xl mb-14 md:mb-24">
                {doc.data.videos_section_button_link.link_type === "Document" ||
                doc.data.videos_section_button_link.link_type === "Any" ? (
                  <Link
                    to={
                      doc.data.videos_section_button_link.uid
                        ? doc.data.videos_section_button_link.uid
                        : `#form/`
                    }
                  >
                    <button className="btn-custom bg-site-red hover:bg-site-red-hover text-xl text-white uppercase tracking-wider">
                      {doc.data.videos_section_button_name
                        ? doc.data.videos_section_button_name
                        : "Register Now"}
                    </button>
                  </Link>
                ) : doc.data.videos_section_button_link.link_type === "Web" ||
                  doc.data.videos_section_button_link.link_type === "Media" ? (
                  <a
                    href={
                      doc.data.videos_section_button_link.url
                        ? doc.data.videos_section_button_link.url
                        : `#form/`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="btn-custom bg-site-red hover:bg-site-red-hover text-xl text-white uppercase tracking-wider">
                      {doc.data.videos_section_button_name
                        ? doc.data.videos_section_button_name
                        : "Register Now"}
                    </button>
                  </a>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      {/* slider */}

      <></>
    </LayoutNew>
  )
}

export default withPrismicPreview(PageSymposiumTemplate)

export const PageSymposiumTemplateQueryNew1 = graphql`
  query ($id: String!) {
    prismicSymposium(id: { eq: $id }) {
      _previewable
      uid
      data {
        title_for_icon {
          richText
          text
        }
        p_for_icon {
          richText
          text
        }
        page_title
        button_name
        hero_section_button_link_type
        button_link {
          uid
          link_type
          url
        }
        form_section_title
        form_id
        red_bubble_text {
          richText
        }
        symposium_description {
          richText
        }
        page_hero_background {
          gatsbyImageData(width: 1920)
        }
        agenda_background {
          gatsbyImageData(width: 1920)
        }
        agenda_section_shown
        presenters_section_shown
        show_button
        presenters_section_button_name
        presenters_section_button_link {
          uid
          link_type
          url
        }
        videos_section_shown
        pictures_section_shown
        agenda {
          activity
          activity_details {
            text
          }
          tile_for_agenda {
            text
          }
          location_for_agenda {
            text
          }
          title
        }
        bio_section {
          name
          position
          short_bio
          section_background
          photo {
            alt
            gatsbyImageData(width: 367)
          }
        }
        videos_description
        videos_title
        show_button1
        videos_section_button_name
        videos_section_button_link {
          uid
          link_type
          url
        }
        videos {
          id
        }
        pictures {
          picture {
            gatsbyImageData(width: 367)
          }
        }
      }
    }
    imgDefault: file(relativePath: { eq: "backgrounds/home-hero-bg.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920)
      }
    }
    photoPlaceholder: file(
      relativePath: { eq: "team/team-photo-placeholder.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 367)
      }
    }
  }
`