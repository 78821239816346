import * as React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { RichText } from 'prismic-reactjs';

import htmlSerializer from '../../utils/htmlSerializer';

const HeroSectionUpdateNew = ({ imgSrc, title, withButton, twoButtons, buttonOneTypeHover, buttonOneName, buttonOneNameHover, buttonOneLinkType, buttonOneLinkUrl, buttonOneLinkUid, buttonTwoTypeHover, buttonTwoName, buttonTwoNameHover, buttonTwoLinkType, buttonTwoLinkUrl, buttonTwoLinkUid, redBubble, redBubbleContent }) => {
  const data = useStaticQuery(graphql`
    query HeroSectionUpdateNewQuery {
      imgDefault: file(relativePath: { eq: "backgrounds/home-hero-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `)

  const imgDefault = getImage(data.imgDefault)

  return (
    <>
      <div className={`${withButton ? "" : "-z-10"} relative pt-14 xl:pt-20`} style={{ display: "grid", background: "#000000", minHeight: "400px" }}>
        <GatsbyImage
          style={{
            gridArea: "1/1",
            objectFit: "cover",
            opacity: "0.7",
            height: 400,
          }}
          alt="hero background"
          image={imgSrc ? imgSrc : imgDefault}
          formats={["auto", "webp", "avif"]}
        />

        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            placeItems: "center",
            display: "grid",
          }}
        >
          {title
            ?
            <div className="w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col items-center justify-center px-2">
              <div className="flex flex-col justify-center">
                <h2 className="text-white text-4xl md:text-6xl font-extrabold text-center mt-4">
                  {title}
                </h2>

                {withButton
                  ?
                  <>
                    {twoButtons
                      ?
                      <div className="flex flex-col items-center justify-center mt-6 xl:mt-8">
                        {buttonOneTypeHover
                          ?
                          <>
                            <div className="hidden xl:block">
                              {buttonOneLinkType === "Document" || buttonOneLinkType === "Any"
                                ?
                                <Link to={buttonOneLinkUid ? `/${buttonOneLinkUid}` : "/"}>
                                  <button className="w-full bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-16 py-3 mb-6 mx-auto">
                                    <span className="default-text mx-2">{buttonOneName ? buttonOneName : "Button Name"}</span>
                                    <span className="hover-text">{buttonOneNameHover ? buttonOneNameHover : "Button Name Hover"}</span>
                                  </button>
                                </Link>
                                :
                                buttonOneLinkType === "Web" || buttonOneLinkType === "Media"
                                ?
                                <a
                                  href={buttonOneLinkUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-16 py-3 mb-6">
                                    <span className="default-text mx-2">{buttonOneName ? buttonOneName : "Button Name"}</span>
                                    <span className="hover-text">{buttonOneNameHover ? buttonOneNameHover : "Button Name Hover"}</span>
                                  </button>
                                </a>
                                :
                                null
                              }
                            </div>

                            <div className="block xl:hidden mt-6 mb-6">
                              <p className="text-white text-2xl font-semibold text-center tracking-wider mb-6">{buttonOneName ? buttonOneName : "Button Name"}</p>
                                {buttonOneLinkType === "Document" || buttonOneLinkType === "Any"
                                  ?
                                  <Link to={buttonOneLinkUid ? `/${buttonOneLinkUid}` : "/"}>
                                    <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-14 py-3">
                                      <span className="mx-1">{buttonOneNameHover ? buttonOneNameHover : "Button Name Hover"}</span>
                                    </button>
                                  </Link>
                                  :
                                  buttonOneLinkType === "Web" || buttonOneLinkType === "Media"
                                  ?
                                  <a
                                    href={buttonOneLinkUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-24 py-3">
                                      <span className="mx-1">{buttonOneNameHover ? buttonOneNameHover : "Button Name Hover"}</span>
                                    </button>
                                  </a>
                                  :
                                  null
                                }
                            </div>
                          </>
                          :
                          <>
                            {buttonOneLinkType === "Document" || buttonOneLinkType === "Any"
                              ?
                              <Link to={buttonOneLinkUid ? `/${buttonOneLinkUid}` : "/"}>
                                <button className="w-full mb-4 xl:mb-0 md:w-auto bg-site-red opacity-70 hover:opacity-90 text-base md:text-lg text-white uppercase tracking-wider px-10 py-3">
                                  {buttonOneName ? buttonOneName : "Button Name"}
                                </button>
                              </Link>
                              :
                              buttonOneLinkType === "Web" || buttonOneLinkType === "Media"
                              ?
                              <Link to={buttonOneLinkUid ? `/${buttonOneLinkUid}` : "/"}>
                                <button className="w-full mb-4 xl:mb-0 md:w-auto bg-site-red opacity-70 hover:opacity-90 text-base md:text-lg text-whites uppercase tracking-wider px-10 py-3">
                                  {buttonOneName ? buttonOneName : "Button Name"}
                                </button>
                              </Link>
                              :
                              null
                            }
                          </>
                        }

                        {buttonTwoTypeHover
                          ?
                          <>
                            <div className="hidden xl:block">
                              {buttonTwoLinkType === "Document" || buttonTwoLinkType === "Any"
                                ?
                                <Link to={buttonTwoLinkUid ? `/${buttonTwoLinkUid}` : "/"}>
                                  <button className="w-full bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-16 py-3 mb-6 mx-auto">
                                    <span className="default-text mx-2">{buttonTwoName ? buttonTwoName : "Button Name"}</span>
                                    <span className="hover-text">{buttonTwoNameHover ? buttonTwoNameHover : "Button Name Hover"}</span>
                                  </button>
                                </Link>
                                :
                                buttonTwoLinkType === "Web" || buttonTwoLinkType === "Media"
                                ?
                                <a
                                  href={buttonTwoLinkUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-16 py-3 mb-6">
                                    <span className="default-text mx-2">{buttonTwoName ? buttonTwoName : "Button Name"}</span>
                                    <span className="hover-text">{buttonTwoNameHover ? buttonTwoNameHover : "Button Name Hover"}</span>
                                  </button>
                                </a>
                                :
                                null
                              }
                            </div>

                            <div className="block xl:hidden mt-6 mb-6">
                              <p className="text-white text-2xl font-semibold text-center tracking-wider mb-6">{buttonTwoName ? buttonTwoName : "Button Name"}</p>
                                {buttonTwoLinkType === "Document" || buttonTwoLinkType === "Any"
                                  ?
                                  <Link to={buttonTwoLinkUid ? `/${buttonTwoLinkUid}` : "/"}>
                                    <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-24 py-3">
                                      <span className="mx-1">{buttonTwoNameHover ? buttonTwoNameHover : "Button Name Hover"}</span>
                                    </button>
                                  </Link>
                                  :
                                  buttonTwoLinkType === "Web" || buttonTwoLinkType === "Media"
                                  ?
                                  <a
                                    href={buttonTwoLinkUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-24 py-3">
                                      <span className="mx-1">{buttonTwoNameHover ? buttonTwoNameHover : "Button Name Hover"}</span>
                                    </button>
                                  </a>
                                  :
                                  null
                                }
                            </div>
                          </>
                          :
                          <>
                            {buttonTwoLinkType === "Document" || buttonTwoLinkType === "Any"
                              ?
                              <Link to={buttonTwoLinkUid ? `/${buttonTwoLinkUid}` : "/"}>
                                <button className="w-full mb-4 xl:mb-0 md:w-auto bg-white opacity-70 hover:opacity-90 text-base md:text-lg text-site-red uppercase tracking-wider px-8 py-3">
                                  {buttonTwoName ? buttonTwoName : "Button Name"}
                                </button>
                              </Link>
                              :
                              buttonTwoLinkType === "Web" || buttonTwoLinkType === "Media"
                              ?
                              <Link to={buttonTwoLinkUid ? `/${buttonTwoLinkUid}` : "/"}>
                                <button className="w-full mb-4 xl:mb-0 md:w-auto bg-white opacity-70 hover:opacity-90 text-base md:text-lg text-site-red uppercase tracking-wider px-8 py-3">
                                  {buttonTwoName ? buttonTwoName : "Button Name"}
                                </button>
                              </Link>
                              :
                              null
                            }
                          </>
                        }
                      </div>
                      :
                      <div className="w-full flex items-center justify-center mt-8">
                        {buttonOneLinkType === "Document" || buttonOneLinkType === "Any"
                          ?
                            <Link to={buttonOneLinkUid ? `/${buttonOneLinkUid}` : "/"}>
                              {buttonOneTypeHover
                                ?
                                <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-20 py-3 mb-6">
                                  <span className="default-text">{buttonOneName ? buttonOneName : "Button Name"}</span>
                                  <span className="hover-text mx-7">{buttonOneNameHover ? buttonOneNameHover : "Button Name Hover"}</span>
                                </button>
                                :
                                <button className="w-full bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-8 py-3">
                                  {buttonOneName ? buttonOneName : "Button Name"}
                                </button>
                              }
                            </Link>
                          :
                          buttonOneLinkType === "Web" || buttonOneLinkType === "Media"
                          ?
                            <a
                              href={buttonOneLinkUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <button className="w-full bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-8 py-3">
                              {buttonOneName ? buttonOneName : "Button Name"}
                              </button>
                            </a>
                          :
                          null
                        }
                      </div>
                    }
                  </>
                  :
                  null
                }
              </div>
            </div>
            :
            null
          }
        </div>
      </div>

      {withButton
        ?
        null
        :
        redBubble
        ?
          <div>
            <div className="red-bubble-section w-11/12 lg:w-10/12 xl:w-11/12 max-w-screen-lg bg-site-red mx-auto pt-12 pb-6 px-6 md:px-16 -mt-8 md:-mt-16 z-20 rounded-3xl shadow-lg">
              <RichText
                render={redBubbleContent}
                htmlSerializer={htmlSerializer}
              />
            </div>
          </div>
        :
        null
      }
    </>
  )
}

export default HeroSectionUpdateNew
