import React from "react"
import { graphql } from "gatsby"
import { StaticImage, getImage, getSrc } from "gatsby-plugin-image"
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import styled from 'styled-components'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import { RichText } from 'prismic-reactjs';

import htmlSerializer from '../utils/htmlSerializer';

import LayoutUpdate from "../components/layout-update"
import HeroSectionUpdate from "../components/common/hero-section-update"
import Seo from "../components/seo"
import HeroSectionUpdateNew from "../components/common/hero-section-update-new"

const WistiaVideo = loadable(() =>
  pMinDelay(import('../components/common/WistiaVideo'), 2000)
)

const pStyle = "text-base md:text-xl mb-6"

const StyledDiv = styled.div`
  min-height: 460px;
`

const PageAdministrativeServices = ({ data }) => {
  const doc = data.prismicAdministrativeServicesPage
  const imgSrc = getSrc(doc.data.page_hero_background)
  const imgHero = getImage(doc.data.page_hero_background)

  return (
    <LayoutUpdate>
      <Seo
        title={doc.data.page_title ? doc.data.page_title : null}
        description={doc.data.page_short_description ? doc.data.page_short_description : null}
        image={imgSrc ? imgSrc : null}
        noIndex={doc.data.page_no_index ? true : false}
      />

      <div>
        <HeroSectionUpdateNew
          imgSrc={imgHero ? imgHero : null}
          title={doc.data.page_title}
          withButton={doc.data.cta_button}
          twoButtons={doc.data.how_many_buttons}
          buttonOneTypeHover={doc.data.cta_button_one_type}
          buttonOneLinkType={doc.data.cta_button_link.link_type}
          buttonOneLinkUid={doc.data.cta_button_link.uid}
          buttonOneLinkUrl={doc.data.cta_button_link.url}
          buttonOneName={doc.data.cta_button_text}
          buttonOneNameHover={doc.data.cta_button_one_hover_text}
          buttonTwoTypeHover={doc.data.cta_button_two_type}
          buttonTwoLinkType={doc.data.cta_button_two_link.link_type}
          buttonTwoLinkUid={doc.data.cta_button_two_link.uid}
          buttonTwoLinkUrl={doc.data.cta_button_two_link.url}
          buttonTwoName={doc.data.cta_button_two_text}
          buttonTwoNameHover={doc.data.cta_button_two_hover_text}
          redBubble={doc.data.red_bubble}
          redBubbleContent={doc.data.red_bubble_content.richText}
        />
      </div>

      {doc.data.show_first_section
        ?
        <div className="bg-white pt-16 md:pt-32 pb-12 md:pb-24">
          <StyledDiv className="relative w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col items-center bg-white  mx-auto p-8 rounded-3xl shadow-lg border border-gray-100">
            <div className="absolute top-0 bg-site-green py-4 px-6 md:px-16 rounded-full -mt-8 mx-auto">
              <h2 className="text-white text-base md:text-2xl font-bold text-center">{doc.data.first_section_title ? doc.data.first_section_title : "Section Title"}</h2>
            </div>

            <div className="w-full max-w-4xl mt-12">
              {doc.data.first_section_content.richText
                ?
                <RichText render={doc.data.first_section_content.richText} htmlSerializer={htmlSerializer} />
                :
                <p className={pStyle}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
              }
            </div>

            <div className="w-full max-w-4xl mx-auto shadow-lg mt-6 mb-6">
              <WistiaVideo wistiaID={doc.data.wistia_id} />
            </div>
          </StyledDiv>
        </div>
        :
        null
      }

      <div
        className="bg-white pt-16 md:pt-32 pb-12 md:pb-24"
      >
        <div className="relative flex flex-col items-center">
          <div className="absolute top-0 bg-site-green py-4 px-6 md:px-16 rounded-full -mt-8 mx-auto z-20">
            <h2 className="text-white text-base md:text-2xl uppercase font-bold text-center">
              {doc.data.second_section_title ? doc.data.second_section_title : "Section Title"}
            </h2>
          </div>
          <div className="relative w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col items-center bg-white mx-auto px-8 pt-8 pb-2 rounded-3xl shadow-lg overflow-hidden z-10 border border-gray-100">
            <div
              className={`green-bubble-slice-section ${
                doc.data.two_column_bullet_list
                  ? "list-two-col"
                  : "list-one-col"
              } w-full max-w-4xl flex flex-col justify-center mt-12 mb-8 z-10`}
            >
              <RichText
                render={doc.data.second_section_content.richText}
                htmlSerializer={htmlSerializer}
              />
            </div>
          </div>
          <div className="absolute bottom-0 -mb-2 opacity-20 z-10">
            <StaticImage
              src="../../images/backgrounds/sra-mtn.png"
              width={600}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="background image"
            />
          </div>
        </div>        
      </div>

      <div
        className="bg-site-green-light pt-16 md:pt-32 pb-12 md:pb-24"
      >
        <div className="relative flex flex-col items-center">
          <div className="absolute top-0 bg-site-green py-4 px-6 md:px-16 rounded-full -mt-8 mx-auto z-20">
            <h2 className="text-white text-base md:text-2xl uppercase font-bold text-center">
              {doc.data.third_section_title ? doc.data.third_section_title : "Section Title"}
            </h2>
          </div>
          <div className="relative w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col items-center bg-white mx-auto px-8 pt-8 pb-2 rounded-3xl shadow-lg overflow-hidden z-10 border border-gray-100">
            <div
              className={`green-bubble-slice-section ${
                doc.data.two_column_bullet_list1
                  ? "list-two-col"
                  : "list-one-col"
              } w-full max-w-4xl flex flex-col justify-center mt-12 mb-8 z-20`}
            >
              <RichText
                render={doc.data.third_section_content.richText}
                htmlSerializer={htmlSerializer}
              />
            </div>
          </div>
        </div>        
      </div>
    </LayoutUpdate>
  )
}

export default withPrismicPreview(PageAdministrativeServices)

export const pageAdministrativeServicesQuery = graphql`
  query($id: String!) {
    prismicAdministrativeServicesPage(id: { eq: $id }) {
      _previewable
      id
      uid
      data {
        page_title
        page_short_description
        page_no_index
        red_bubble        
        red_bubble_content {
          richText
        }
        page_hero_background {
          gatsbyImageData(
            width: 1920
          )
        }
        cta_button
        how_many_buttons
        cta_button_one_type
        cta_button_text
        cta_button_one_hover_text
        cta_button_link {
          link_type
          uid
          url
        }
        cta_button_two_type
        cta_button_two_text
        cta_button_two_hover_text
        cta_button_two_link {
          link_type
          uid
          url
        }
        show_first_section
        first_section_title
        first_section_content {
          richText
        }
        wistia_id 
        second_section_title
        second_section_content {
          richText
        }
        two_column_bullet_list
        third_section_title
        third_section_content {
          richText
        }
        two_column_bullet_list1
      }
    }
  }
`